<template>
    <div class="show-pdf">
      <div>
        <pdf
          v-if="pdfSrc"
          :src="pdfSrc"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          @loaded="loadPdfHandler"
        ></pdf>
      </div>
      <span v-if="pdfSrc">
        <a-button type="primary" @click="changePdfPage(0)">上一页</a-button>
        {{ currentPage }} / {{ pageCount }}
        <a-button type="primary" @click="changePdfPage(1)">下一页</a-button>
      </span>
    </div>

</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'Pdf',
  components: {
    pdf
  },
//   props: ['dochref', 'doctype'],
//   watch: {
//     dochref (val) {
//       console.log('pdfSrc')
//       console.log(val)
//       this.pdfSrc = val
//     },
//     pdfSrc (val) {},
//     doctype (typeval) {
//       this.typeValue = typeval
//     }
//   },

  data () {
    return {
      typeValue: '',
      pdfSrc: 'https://xeoss.xuan1tech.com/xecommon/xeuagreement/U9061000004.pdf',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      numPages: 1,
      activeIndex: 0
    }
  },
  methods: {
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage (val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
        scrollTo(0,0)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
        scrollTo(0,0)
      }
    },
    // pdf加载时
    loadPdfHandler () {
      this.currentPage = 1 // 加载的时候先加载第一页
    }
  },
//   mounted: function () {
//     this.pdfSrc = ''
//     this.pdfSrc = this.dochref
//   }
}
</script>
<style lang="less" scoped>
.show-pdf {
  width: 100%;
  padding:5% 20%;
  background: #fff;
  margin: 0 auto;
  text-align: center;
}
</style>
